<template>
    <div>
        <div class="renderer">
            <slot name="Start" />
        </div>
        <div class="renderer" v-if="showEventResults">
            <div>
                <slot name="eventTotalNoteLabel">{{ eventNoteLabel }}</slot>
            </div>
            <div style="display: flex; align-content: center; align-items:center;">
                <div class="global" style="margin-right:20px;">{{ eventNote }}</div>
                <b-badge pill size="2.8em" variant="warning" class="rank">{{ (eventRank > 0) ? eventRank : 'N/A' }}<sup>{{ getRankSup(eventRank) }}</sup></b-badge>
            </div>
        </div>
        <div class="renderer" v-if="showEventResults">
            <slot name="betweenEventAndOverallInfos" />
        </div>
        <hr v-if="showEventResults && showOverallResults" />
        <div class="renderer" v-if="showOverallResults && (overallNote !== null)">
            <div>
                <slot name="overallNoteLabel">{{ overallNoteLabel }}</slot>
            </div>
            <div style="display: flex; align-content: center; align-items:center;">
                <div class="global" style="margin-right:20px;">{{ overallNote }}</div>
                <b-badge pill size="2.8em" variant="warning" class="rank">{{ (overallRank > 0) ? overallRank : 'N/A' }}<sup>{{ getRankSup(overallRank) }}</sup></b-badge>
            </div>
        </div>
        <div class="renderer">
            <slot name="End">
                <b-button class="nextStep" variant="primary" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
    },
    data(){
        return {
            eventNoteLabel: 'Note finale & classement provisoire de l\'épreuve',
            eventNote: '',
            eventRank: '',
            overallNoteLabel: 'Note globale provisoire et classement provisoire pour la compétition',
            overallNote: '',
            overallRank: '',
            showEventRank: true,
            showEventResults: true,
            showOverallResults: true,
        };
    },
    computed:{
        //...mapGetters('currentState', ['currentCategory', 'currentCompetitor', 'currentEvent'] ),
    },
    methods:{
        reset(){
            this.eventNote = '';
            this.eventRank = '';
            this.overallNote = '';
            this.overallRank = '';
            this.showEventResults = true;
            this.showEventRank = true;
            this.showOverallResults = true;
            this.eventNoteLabel = 'Note finale de l\'épreuve & classement provisoire pour l\'épreuve';
            this.overallNoteLabel = 'Note globale (provisoire) et classement provisoire pour la compétition';
        },
        refresh(){
            this.reset();

            this.eventNote = this.arrondir(this.currentEvent.CURRENT_COMPETITOR.note);
            this.eventRank = this.currentEvent.CURRENT_COMPETITOR.rank;

            this.overallNote = this.arrondir(this.currentEvent.CURRENT_COMPETITOR.RST_COMPETITOR.note);
            this.overallRank = this.currentEvent.CURRENT_COMPETITOR.RST_COMPETITOR.rank;
        },
        getRankSup(rank){
            if(rank === null || rank === '' || rank === "N/A" || rank === '0' || rank === 0){
                return '';
            }

            if(rank === 1 || rank ==='1') {
                return 'er';
            } else if(rank === 2 || rank ==='2'){
                return 'nd';
            } else {
                return 'ème';
            }
        },
        /*
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },*/
        nextStep(){
            this.$emit("nextStep");
        },
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .global.details{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .global.execution, .global.choreo{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 45px;
        font-size:0.25em;
        border:none;
        font-weight: lighter;
    }
    .global.execution > .note, .global.choreo > .note{
        font-size:2.5em;
        font-weight: lighter;
    }
    .global.choreo {
        border-top:solid 1px black;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        font-size:0.8em; border: solid 1px darkgray; color:darkgray; width:80px; height:80px; margin: 10px 10px;
        padding: 2px 0px;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .judgeNote > .execution, .judgeNote > .choreo
    {
        font-size:0.9em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2.5em;
        /*border:solid 1px darkgreen; background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .rank{
        font-size:2em;
    }

</style>
